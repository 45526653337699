import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from '@components/Button';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Button />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button mdxType="Button" />
    </Playground>
    <h2 {...{
      "id": "title"
    }}>{`Title`}</h2>
    <Playground __position={2} __code={'<Button title=\"Click me\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button title="Click me" mdxType="Button" />
    </Playground>
    <h2 {...{
      "id": "with-different-types"
    }}>{`With different types`}</h2>
    <Playground __position={3} __code={'<Button type=\"solid\" />\n<Button type=\"outline\" />\n<Button type=\"clear\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button type="solid" mdxType="Button" />
  <Button type="outline" mdxType="Button" />
  <Button type="clear" mdxType="Button" />
    </Playground>
    <h2 {...{
      "id": "with-different-sizes"
    }}>{`With different sizes`}</h2>
    <Playground __position={4} __code={'<Button size=\"large\" />\n<Button size=\"medium\" />\n<Button size=\"small\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button size="large" mdxType="Button" />
  <Button size="medium" mdxType="Button" />
  <Button size="small" mdxType="Button" />
    </Playground>
    <h2 {...{
      "id": "enable--disabled"
    }}>{`Enable / Disabled`}</h2>
    <Playground __position={5} __code={'<Button />\n<Button disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button mdxType="Button" />
  <Button disabled mdxType="Button" />
    </Playground>
    <h2 {...{
      "id": "loading"
    }}>{`Loading`}</h2>
    <Playground __position={6} __code={'<Button loading />\n<Button type=\"outline\" loading />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button loading mdxType="Button" />
  <Button type="outline" loading mdxType="Button" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      